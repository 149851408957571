<template>
    <div class="d-flex">
        <button :disabled="!goBackTo" @click="goback" class="btn my-auto">
          <img src="@/assets/images/arrow_left.svg" alt="back" />
        </button>
        <b-breadcrumb class="my-auto py-0" :items="items"></b-breadcrumb>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => {
                return []
            },
        },
        goBackTo: Object
    },

    methods: {
        goback() {
            if(this.goBackTo) {
                this.$router.push(this.goBackTo);
            }
        }
    }
}
</script>

<style scoped>

/* Add a color to all links inside the list */
a {
    color: rgba(0, 0, 0, 0.50);
    text-decoration: none;
    font-style: normal;
}

button {
    width: 44px;
    height: 44px;
    border-radius: 12px;
    padding: 0;
    background: rgb(217,217,217, 0.3);
    border: none;
}
li{
    font-size: 12px;
}
li:last-child {
    color: #FC6B2D;
}

ol {
    background-color: #FFF !important;
}
</style>