<template>
    <div class="d-flex w-75" style="height: 44px; gap: 10px;">
        <label v-if="options" class="my-auto text-black">Search by:</label>
        <div class="input-group w-75 search-by-border bg-white">
            <div v-if="options" class="input-group-prepend">
                <b-form-select
                    v-model="$attrs.value.key"
                    class="custom-dropdown-select"
                    :options="options"
                    @input="handleInput"
                >
                </b-form-select>
            </div>

            <div class="input-group-prepend mx-2" v-if="hideSearchButton">
                <div class="d-flex">
                    <img src="@/assets/images/search.svg" alt="search" class="my-auto" />
                </div>
            </div>
    
            <b-form-input
                v-model="$attrs.value.value"
                @input="handleInput"
                class="my-auto border-0 ml-1"
                placeholder="Search..."
                @keypress.enter="clicked"
            ></b-form-input>
    
            <div class="input-group-append" v-if="!hideSearchButton">
                <div class="d-flex">
                    <button class="btn common-bg-30 p-0" @click="clicked">
                        <img src="@/assets/images/search.svg" alt="search" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: Array,
        hideSearchButton: {
            Type: Boolean,
            default: false
        }
    },
    created() {
        if(this.options && this.options.length > 0) {
            this.$attrs.value.key = this.options[0];
        }
    },
    methods: {
        clicked() {
            this.$emit('search-by-clicked')
        },
        handleInput () {
            this.$emit('input', this.$attrs.value);
            this.clicked();
        }
    }
}
</script>

<style scoped>
label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
}
select {
    background-color: rgba(220, 218, 213, 0.30);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
}

select:focus{
    box-shadow: none;
}

input {
    height: unset;
    font-size: 14px;
}

input:focus {
    border-color: #DCDAD5;
}
button {
    border: 0;
    border-radius: 8px;
    height: 36px;
    width: 36px;
    margin-top: 3px;
    margin-right: 3px;
}
.search-by-border {
    border: 1px solid #DCDAD5 !important;
    border-radius: 12px !important;
    height: 44px;
}

</style>